<template>
  <Form
    :form-fields="formFields"
    :form-error="formError"
    class="newsletter-form"
    :is-loading="isSending"
    @submit="submit"
  />
</template>

<script lang="ts" setup>
import sendIcon from '@/assets/icons/send.svg?raw'
import useFormNonFieldError from '@/composables/useFormNonFieldError'
import type {
  PublicSubscribeRequest,
  PublicNewsletterSettings
} from '@/service/__generated-api'

const { t } = useI18n()

const props = defineProps<{ settings: PublicNewsletterSettings }>()

const { formError, setFormError } = useFormNonFieldError()

const isSending = ref(false)

const formFields = [
  {
    label: '',
    name: 'email',
    rules: 'required|email',
    placeholder: t('newsletter.email'),
    type: 'text',
    header: '',
    class: 'field--half newsletter-field'
  },
  {
    label: t('newsletter.subscribe'),
    name: 'submit',
    type: 'submit',
    header: '',
    icon: sendIcon,
    class: 'field--half submit-button'
  }
]

const addAgreementsFromSettings = () => {
  Object.entries(props.settings).forEach(([key, setting], index) => {
    if (typeof setting !== 'string' && key !== 'id') {
      formFields.push({
        label: setting.text,
        header: index === 0 ? t('newsletter.want-to-receive') : '',
        name: key,
        rules: setting.is_required ? 'agreement-required' : '',
        type: 'checkbox',
        class: key !== 'third_agreement' ? 'field--half' : '',
        placeholder: ''
      })
    }
  })
}

addAgreementsFromSettings()

const emit = defineEmits(['success'])

const submit = async (formData: PublicSubscribeRequest) => {
  isSending.value = true

  try {
    await useApi().publicNewsletterSubscribeCreate(formData)
    emit('success')
  } catch (error) {
    setFormError(error)
  }

  isSending.value = false
}
</script>

<style lang="scss" scoped>
.newsletter-form :deep() {
  display: flex;
  flex-wrap: wrap;
  column-gap: rem(10px);

  @include media-up(md) {
    column-gap: rem(17px);
    align-items: flex-end;
    margin-bottom: rem(50px);
  }

  @include media-up(xl) {
    column-gap: rem(23px);
  }

  .field {
    &:nth-child(1) {
      width: calc(100% - rem(58px) - rem(10px));

      @include media-up(md) {
        width: calc(100% - rem(104px) - rem(17px));
      }

      @include media-up(xl) {
        width: calc(100% - rem(146px) - rem(23px));
      }
    }

    &:nth-child(n + 3) {
      width: 100%;

      @include media-up(md) {
        width: auto;
        margin-left: rem(8px);
      }
    }

    &:nth-child(3) {
      @include media-up(md) {
        margin-left: rem(30px);
      }
    }

    &:nth-child(5) {
      @include media-up(lg) {
        width: 100%;
        margin-left: rem(30px);
      }
    }
  }

  .field--submit {
    @include media-up(md) {
      align-self: flex-start;
    }
  }

  .header {
    @include media-up(md) {
      display: block;
      width: 1px;
      white-space: pre;
    }
  }

  .button {
    --button-text-color: #{$beige};
    --button-bg-color: #{$dark-brown};
    --button-bg-hover-color: #{$dark-brown};
    --button-text-hover-color: #{$beige};
    aspect-ratio: 1/1;
    border-radius: rem(2px);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);

    @include media-up(md) {
      width: rem(104px);
      aspect-ratio: auto;
    }

    @include media-up(xl) {
      width: rem(146px);
    }

    .icon {
      @include hide-up(md);

      @include media-down(md) {
        margin: 0;
      }
    }

    .text {
      @include hide-down(md);
    }
  }
}

:deep(.newsletter-field input::placeholder) {
  text-transform: uppercase;
}
</style>
