import axios, { AxiosError } from 'axios'
import { ref, type Ref } from 'vue'

type FormError = {
  formError: Ref<string>
  setFormError: (error: unknown) => void
}

export default function useFormNonFieldError(
  defaultMessage = 'Something went wrong'
): FormError {
  const formError = ref('')

  const setFormError = (err: unknown) => {
    if (axios.isAxiosError(err)) {
      formError.value =
        (err as AxiosError<string, unknown>).response?.data[0] ?? defaultMessage
    } else {
      throw err
    }
  }

  return { formError, setFormError }
}
